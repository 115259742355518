import {
  add,
  flush,
  init,
  setTransport,
  track,
} from "@amplitude/analytics-browser"
import { MemoryStorage } from "@amplitude/analytics-core"
import { PageHide, UrlChange } from "./instrumentations"
import { env } from "../utils/env"
import { locationPlugin, staticValuesPlugin } from "./plugins"

const config = {
  defaultTracking: false,
  trackingOptions: { ipAddress: false },

  disableCookies: true,
  cookieStorageProvider: new MemoryStorage(),
  identityStorage: "none",
}

const defaultInstrumentations = [
  new UrlChange(track),
  new PageHide(() => {
    setTransport("beacon")
    flush()
  }),
]

/**
 * Initialize analytics and telemetry.
 */
export function initialize(instrumentations = defaultInstrumentations) {
  // if no key just return without init
  if (!env.REACT_APP_AMPLITUDE_PROJECT_KEY) {
    return
  }

  // init amplitude, userId added later after authentication
  add(staticValuesPlugin())
  add(locationPlugin())
  init(env.REACT_APP_AMPLITUDE_PROJECT_KEY, "", config)

  instrumentations.forEach((i) => i.start())
}
