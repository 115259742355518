import { Text, Button } from "grommet"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { feedbackButton, getTestIdAttribute } from "../utils/test-ids"
import { getRoute } from "../utils/location"

const Feedback = styled(Button)`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateX(38%) translateY(50%) rotate(-90deg);
  background-color: #ffffff;
  border-color: #425563;
  border-width: 2px 2px 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  padding: 4px 17px 10px;

  &:hover {
    background-color: #f5f5f5;
    border-width: 3px 3px 0;
    padding: 3px 16px 10px;
  }

  @media (width < 1280px), (height < 768px) {
    display: none;
  }
`

export default function FeedbackButton() {
  const { t } = useTranslation()

  const defaultFeedbackLink =
    "https://www.hpe.com/ww/feedback-hpegreenlake-dataservices"

  const { feedbackLink } = getRoute(window.location.pathname)

  return (
    <Feedback
      href={feedbackLink || defaultFeedbackLink}
      target="_blank"
      rel="noopener noreferrer"
      label={
        <Text weight={600} size="16px" color="#425563">
          {t("sendFeedback")}
        </Text>
      }
      secondary
      type="button"
      {...getTestIdAttribute(feedbackButton)}
    />
  )
}
