import { useLocation, useNavigate } from "react-router-dom"
import { Button, Heading } from "grommet"
import { getTestIdAttribute, header } from "../utils/test-ids"

export default function BrandButton({ children }) {
  const location = useLocation()
  const navigate = useNavigate()

  const isLaunchpad = location.pathname === "/"

  return (
    <Button
      href="/"
      style={isLaunchpad ? { cursor: "default" } : {}}
      label={
        <Heading weight={600} size="18px">
          {children}
        </Heading>
      }
      onClick={(event) => {
        event.preventDefault()
        if (!isLaunchpad) navigate("/")
      }}
      plain
      {...getTestIdAttribute(header.brand)}
    />
  )
}
