import { jwtDecode } from "jwt-decode"
import { I18N_STORAGE_KEY } from "../i18n-config/i18n"
import { env } from "../utils/env"

// istanbul ignore next
const noop = () => {}

export default class MockAuthService {
  userManager = {
    events: {
      addUserLoaded: noop,
      removeUserLoaded: noop,
    },

    getUser: () => {
      const access_token = jwtDecode(env.REACT_APP_AUTH_TOKEN)
      return Promise.resolve({
        id_token: env.REACT_APP_AUTH_TOKEN ?? "some.id.token",
        access_token: env.REACT_APP_AUTH_TOKEN,
        expires_at: access_token.exp,
        profile: {
          acr: "urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified",
          auth_time: access_token.iat,
          email: access_token.sub,
          family_name: access_token.family_name,
          given_name: access_token.given_name,
          jti: "somejti",
          "pi.sri": "some..pi.sri",
          sub: access_token.sub,
        },
        refresh_token: "somerefreshtoken",
        scope: "openid profile email",
        session_state: undefined,
        token_type: "Bearer",
        expired: false,
        expires_in: 2583,
        scopes: ["openid", "profile", "email"],
      })
    },

    signinRedirectCallback: () => Promise.resolve(),
  }

  getApplicationCustomerID = async () => {
    const user = await this.getUser()

    // decode JWT and get user_ctx claim
    return this.getUserCtx(user?.access_token)
  }

  getUser = () => this.userManager.getUser()

  getUserCtx = (token) => {
    let id
    try {
      const payload = jwtDecode(token)
      id = payload.user_ctx
    } catch (err) {
      console.error(err)
    }
    return id
  }

  login = () => {
    window.location.replace("/auth")
  }

  logout = () => {
    localStorage.removeItem(I18N_STORAGE_KEY)
    return new Promise((resolve) => {
      setTimeout(() => {
        window.location.assign("/idp/startSLO.ping")
        resolve()
      }, 500)
    })
  }

  renewToken = noop

  // receive callback from idp after signinRedirect
  signinRedirectCallback = () => this.userManager.signinRedirectCallback()
}
