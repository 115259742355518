import { useEffect } from "react"

/**
 * Single Logout endpoint
 */
export function Logout() {
  // clear any remaining storage
  useEffect(() => {
    try {
      localStorage.clear()
      sessionStorage.clear()
    } catch {}
  }, [])

  return null
}
