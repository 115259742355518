import { Box, Button } from "grommet"
import { Notification } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuthZ } from "../hooks"
import { getTestIdAttribute, header } from "../utils/test-ids"

export default function IssuesButton() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { permissions } = useAuthZ()

  return (
    <Button
      disabled={!permissions.includes("issue.read")}
      href="/issues"
      icon={<Notification size="large" />}
      onClick={(event) => {
        event.preventDefault()
        if (permissions.includes("issue.read")) {
          navigate("/issues")
        }
      }}
      tip={{
        content: (
          <Box {...getTestIdAttribute(header.issues.tip)}>{t("tipIssues")}</Box>
        ),
      }}
      {...getTestIdAttribute(header.issues.icon)}
    />
  )
}
