import { Box, Button, Text } from "grommet"
import { useTranslation } from "react-i18next"
import authService from "../oauth/auth-service"
import { getTestIdAttribute, userProfile } from "../utils/test-ids"

const profileMenu = [
  {
    onClick: authService.logout,
    title: "headerProfileMenuLogout",
    testIds: userProfile.logout,
  },
]

export default function ProfileMenu({ name }) {
  const { t } = useTranslation()

  return (
    <Box
      width={{ max: "medium" }}
      {...getTestIdAttribute(userProfile.container)}
    >
      <Box border={{ side: "bottom", size: "1px", color: "#eee" }} pad="20px">
        <Text
          color="text-strong"
          size="20px"
          weight={500}
          style={{
            textTransform: "capitalize",
          }}
          {...getTestIdAttribute(userProfile.name)}
        >
          {name}
        </Text>
      </Box>
      <Box>
        {profileMenu.map((menuItem) => (
          <Button
            fill
            key={menuItem.title}
            label={
              <Box align="start" pad={{ horizontal: "8px", vertical: "4px" }}>
                <Text size="16px" weight="normal">
                  {t(menuItem.title)}
                </Text>
              </Box>
            }
            onClick={menuItem.onClick}
            {...getTestIdAttribute(menuItem.testIds)}
          />
        ))}
      </Box>
    </Box>
  )
}
