import { Routes, Route } from "react-router-dom"
import { CCSLogin } from "./ccs-login"
import { CCSLogout } from "./ccs-logout"

export function MockCCS({ enabled }) {
  return (
    <>
      {enabled ? (
        <Routes>
          <Route path="login" element={<CCSLogin />} />
          <Route path="logout" element={<CCSLogout />} />
        </Routes>
      ) : null}
    </>
  )
}
