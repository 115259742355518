import { useState } from "react"
import { FormDown } from "grommet-icons"
import { Box, DropButton, Spinner, Text } from "grommet"
import MiniLaunchPadContent from "./mini-launchpad-content"
import { useTranslation } from "react-i18next"
import { useTiles } from "../hooks"
import { launchpadItems } from "../routes/launchpad/launchpad-data"
import styled from "styled-components"
import { getTestIdAttribute, miniLaunchpad } from "../utils/test-ids"

const LaunchpadButton = styled(DropButton)`
  border-radius: 6px;
  background-color: #f4f6f9;
  &:hover {
    background-color: #e2e6ea;
  }
`

const MiniLaunchPad = ({ serviceTitle }) => {
  const [open, setOpen] = useState()
  const { t } = useTranslation()
  const { loading, tiles } = useTiles(launchpadItems)

  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const dropContent = (
    <Box pad="20px">
      {loading ? (
        <Spinner
          alignSelf="center"
          {...getTestIdAttribute(miniLaunchpad.loading)}
        />
      ) : (
        <MiniLaunchPadContent contents={tiles} setOpen={setOpen} />
      )}
    </Box>
  )

  return (
    <Box align="center">
      <LaunchpadButton
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        dropContent={dropContent}
        dropAlign={{ top: "bottom", left: "left" }}
        {...getTestIdAttribute(miniLaunchpad.button)}
      >
        <Box
          direction="row"
          gap="small"
          align="center"
          pad="4px 12px"
          {...getTestIdAttribute(miniLaunchpad.box)}
        >
          <Text
            color="text-strong"
            {...getTestIdAttribute(miniLaunchpad.title)}
          >
            {t(serviceTitle)}
          </Text>
          <FormDown color="icon" size="16px" />
        </Box>
      </LaunchpadButton>
    </Box>
  )
}

export default MiniLaunchPad
