import { useTranslation } from "react-i18next"
import ShellHeader from "./shell-header"
import UserAvatar from "./user-avatar"
import { Products } from "../utils/constants"

export default function ROPHeader({ serviceTitle }) {
  const { t } = useTranslation()

  return (
    <ShellHeader brandTitle={t(Products.ROP)} serviceTitle={serviceTitle}>
      <UserAvatar />
    </ShellHeader>
  )
}
