import { useEffect, useState } from "react"
import authService from "../oauth/auth-service"

export default function useUser() {
  const [user, setUser] = useState(null)

  // load user from storage
  useEffect(() => {
    const loadUser = async () => {
      const user = await authService.getUser()
      if (user) {
        setUser(user)
      }
    }

    loadUser()
  }, [])

  // userLoaded event fires ONLY when signinRedirectCallback is completed
  // not when page refreshed and user loaded from storage
  useEffect(() => {
    const updateUser = async () => {
      const user = await authService.getUser()
      setUser(user)
    }

    authService.userManager.events.addUserLoaded(updateUser)

    return () => authService.userManager.events.removeUserLoaded(updateUser)
  }, [])

  return user
}
