import { Box, Text } from "grommet"
import { useTranslation } from "react-i18next"
import { launchpadItems } from "../launchpad/launchpad-data"
import { ServiceItem } from "./service-item"
import { getTestIdAttribute, additionalServices } from "../../utils/test-ids"

export function AdditionalServicesContent({ setOpen, setModalData }) {
  const { t } = useTranslation()
  return (
    <Box
      align="center"
      flex="grow"
      pad={{ horizontal: "medium", top: "20px", bottom: "medium" }}
      {...getTestIdAttribute(additionalServices.box)}
    >
      <Box
        gap="small"
        direction="row"
        align="baseline"
        alignSelf="start"
        pad="0px 32px 20px"
      >
        <Text color="text-strong" size="18px" weight={400}>
          {t("learnMoreHome")}
        </Text>
        <Text size="32px" color="text-strong" weight={500}>
          {t("learnMoreWhatsNew")}
        </Text>
      </Box>
      <Box gap="small">
        {launchpadItems
          .filter((c) => c.key === "atlas") // additional temporary filter to get ONLY atlas data
          .map((c) => (
            <ServiceItem
              key={c.key}
              {...c}
              setOpen={setOpen}
              setModalData={setModalData}
            />
          ))}
      </Box>
    </Box>
  )
}
