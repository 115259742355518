import { env } from "../utils/env"

export const {
  REACT_APP_APP_INSTANCE_ID: APP_INSTANCE_ID,
  REACT_APP_MOCK_AUTH: MOCK_AUTH,
  REACT_APP_OIDC_AUTHORITY: AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID: CLIENT_ID,
} = env

const { REACT_APP_CCS_LOGIN: CCS_LOGIN } = env

const url = `${window.location.protocol}//${window.location.host}`
export const CCS_LOGIN_URL = MOCK_AUTH ? `${url}/ccs/login` : CCS_LOGIN

export const testApplicationCustomerId = "sc-test-customer-id"

export const CID_KEY = "dscc-cid"

export const REDIRECT_URI_KEY = "dscc-redirect-uri"
