import { useLocation } from "react-router-dom"
import { getTestIdAttribute, notFoundPage } from "../utils/test-ids"

export function NotFound() {
  const location = useLocation()

  return (
    <div {...getTestIdAttribute(notFoundPage)}>
      {process.env.NODE_ENV === "development" && (
        <pre>{JSON.stringify(location, null, 2)}</pre>
      )}
    </div>
  )
}
