import { matchRoutes } from "react-router-dom"
import { Products } from "./constants"
import i18n from "../i18n-config/i18n"
import { routes } from "../shell-routes"

const microAppRoutes = Object.values(routes)

export function getRoute(uri) {
  const matched = matchRoutes(microAppRoutes, uri)

  const route =
    microAppRoutes.find(
      ({ path }) => path && matched?.[0].route.path === path
    ) ?? routes.launchpad

  return route
}

export function getRouteName(uri) {
  const route = getRoute(uri)
  return i18n.t(route.title) || Products.DSCC
}
