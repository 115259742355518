import { useState } from "react"
import { AdditionalServicesContent } from "./additional-services-content"
import { ExternalLinkConfirmation } from "../../components"

export function AdditionalServices() {
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  return (
    <>
      <AdditionalServicesContent
        setOpen={setOpen}
        setModalData={setModalData}
      />
      {open && <ExternalLinkConfirmation {...modalData} setOpen={setOpen} />}
    </>
  )
}
