import { useFlags } from "launchdarkly-react-client-sdk"
import useAuthZ from "./use-authz"
import useSubscriptions from "./use-subscriptions"
import { routes } from "../shell-routes"
import { isAccessible } from "../utils/accessible"

export default function useTiles(tileData) {
  // First obtain the users's authz permissions, subscription product categories, and LaunchDarkly flags.
  const { permissions, loading } = useAuthZ()
  const { subscriptions } = useSubscriptions()
  const userLDFlags = useFlags()

  // Iterate over all specified tiles
  const tiles = tileData.reduce((acc, p) => {
    // Determine accessibility of the tile based on permissions, subscriptions, and LD flags.
    const { hasTileAccess, hasPermissionAccess, hasSubscriptionAccess } =
      isAccessible({
        userLDFlags,
        requiredLDFlag: p.flag,
        userPermissions: permissions,
        requiredPermissions: p.resources,
        userSubscriptions: subscriptions,
        requiredSubscriptions: p.subscriptions,
      })

    // Determine the destination path of the tile link.  This will be the tile's learn-more path if the tile should
    // always be shown and the user doesn't have a subscription.  Otherwise, if the user doesn't have permissions then
    // the destination should be the the generic unauthorized path.  Otherwise, its the tile's default desintaion path.
    const destinationPath =
      hasSubscriptionAccess || !p.alwaysShow
        ? hasPermissionAccess
          ? p.to
          : routes.unauthorized.navPath
        : p.toMore

    // Determine the button label, which should be the learn-more label if the tile should always be shown and the user
    // doesn't have a subscription.  Otherwise, its just the tile's default label.
    const tileButtonLabel =
      hasSubscriptionAccess || !p.alwaysShow
        ? p.buttonLabel
        : p.learnMoreButtonLabel

    // Always show a tile if it is associated with an external service, or if it should always be shown, or if the user
    // has access to the tile's underlying functionality.
    const showTile = p.type === "external" || p.alwaysShow || hasTileAccess

    if (showTile) {
      acc.push({ ...p, destinationPath, tileButtonLabel })
    }

    return acc
  }, [])

  return { loading, tiles }
}
