import { useEffect } from "react"
import { useRoutes } from "react-router-dom"
import { useFlags } from "launchdarkly-react-client-sdk"
import { AdditionalServices } from "./additional-services"
import { Block } from "./block"
import { DSCCHeader, ROPHeader } from "../components"
import { Dom } from "./dom"
import { SHELL_RTM } from "../events"
import { useSubscriptions } from "../hooks"
import { Launchpad } from "./launchpad"
import { NotFound } from "./not-found"
import { VCF } from "./vcf"
import { DSCCMicroAppRoute, ROPMicroAppRoute, ShellRoute } from "./shell-route"
import { routes } from "../shell-routes"

export function AuthenticatedDSCCRoutes() {
  const {
    "shell-enable-announcements-1": shellEnableAnnouncements1,
    "shell-enable-audit-1": shellEnableAudit1,
    "shell-enable-email-notifications-1": shellEnableEmailNotifications1,
    "shell-enable-file-storage-1": shellEnableFileStorage1,
    "shell-enable-groups-1": shellEnableGroups1,
    "shell-enable-objects-1": shellEnableObjects1,
    "shell-enable-private-cloud-ai": shellEnablePrivateCloudAI,
    "shell-enable-rtm-1": shellEnableRTM1,
    "shell-enable-search-1": shellEnableSearch1,
    "shell-enable-sfm-1": shellEnableSFM1,
    "shell-enable-shapeshifter-1": shellEnableShapeshifter1,
    "shell-enable-zerto-1": shellEnableZerto1,
    "dscc-data-observability": dsccDataObservability,
    "dscc-webhooks-service": dsccWebhooksService,
    "ezmeral-kubernetes-service": ezmeralKubernetesService,
  } = useFlags()

  const { subscriptions } = useSubscriptions()

  useEffect(() => {
    if (shellEnableRTM1) {
      window.dispatchEvent(new Event(SHELL_RTM.INIT))
      return () => {
        window.dispatchEvent(new Event(SHELL_RTM.CLEANUP))
      }
    }
  }, [shellEnableRTM1])

  return useRoutes(
    [
      {
        path: routes.launchpad.path,
        element: (
          <ShellRoute Header={DSCCHeader}>
            <Launchpad />
          </ShellRoute>
        ),
      },
      {
        path: "*",
        element: (
          <ShellRoute Header={DSCCHeader}>
            <NotFound />
          </ShellRoute>
        ),
      },
      {
        path: routes.additionalServices.path,
        element: (
          <ShellRoute
            Header={DSCCHeader}
            title={routes.additionalServices.title}
          >
            <AdditionalServices />
          </ShellRoute>
        ),
      },
      shellEnableAnnouncements1 && {
        path: routes.announcements.path,
        element: <DSCCMicroAppRoute route={routes.announcements} />,
      },
      {
        path: routes.atlas.path,
        element: <DSCCMicroAppRoute route={routes.atlas} />,
      },
      shellEnableAudit1 && {
        path: routes.audit.path,
        element: <DSCCMicroAppRoute route={routes.audit} />,
      },
      {
        element: <Block />,
        children: [
          { path: routes["block-arcus"].path, element: <Block.Arcus /> },
          { path: routes.block.path, element: <Block.Dashboard /> },
          {
            path: routes["block-data-access"].path,
            element: <Block.DataAccess />,
          },
          {
            path: routes["block-edgestack"].path,
            element: <Block.EdgeStack />,
          },
          { path: routes["block-fleet"].path, element: <Block.Fleet /> },
          { path: routes["block-nimble"].path, element: <Block.Nimble /> },
          { path: routes["block-primera"].path, element: <Block.Primera /> },
        ],
      },
      dsccDataObservability && {
        path: routes.dataObservability.path,
        element: <DSCCMicroAppRoute route={routes.dataObservability} />,
      },
      {
        element: <Dom />,
        children: [
          {
            path: routes["dom-apollo-object"].path,
            element: <Dom.ApolloObject />,
          },
          { path: routes["dom-arcus"].path, element: <Dom.Arcus /> },
          { path: routes["dom-data-access"].path, element: <Dom.DataAccess /> },
          { path: routes["dom-edgestack"].path, element: <Dom.EdgeStack /> },
          { path: routes["dom-file"].path, element: <Dom.File /> },
          { path: routes["dom-fleet"].path, element: <Dom.Fleet /> },
          { path: routes["dom-homefleet"].path, element: <Dom.Homefleet /> },
          { path: routes["dom-nimble"].path, element: <Dom.Nimble /> },
          {
            path: routes["dom-objectStorage"].path,
            element: <Dom.ObjectStorage />,
          },
          { path: routes["dom-primera"].path, element: <Dom.Primera /> },
          { path: routes["dom-sds"].path, element: <Dom.SDS /> },
        ],
      },
      {
        path: routes.dualAuth.path,
        element: <DSCCMicroAppRoute route={routes.dualAuth} />,
      },
      shellEnableEmailNotifications1 && {
        path: routes.email.path,
        element: <DSCCMicroAppRoute route={routes.email} />,
      },
      shellEnableFileStorage1 && {
        path: routes["file-manager"].path,
        element: <DSCCMicroAppRoute route={routes["file-manager"]} />,
      },
      shellEnableGroups1 && {
        path: routes.groups.path,
        element: <DSCCMicroAppRoute route={routes.groups} />,
      },
      {
        path: routes.issues.path,
        element: <DSCCMicroAppRoute route={routes.issues} />,
      },
      ezmeralKubernetesService && {
        path: routes.kubernetesService.path,
        element: <DSCCMicroAppRoute route={routes.kubernetesService} />,
      },
      shellEnableObjects1 && {
        path: routes.objectStorage.path,
        element: <DSCCMicroAppRoute route={routes.objectStorage} />,
      },
      shellEnablePrivateCloudAI && {
        path: routes.pcai.path,
        element: <DSCCMicroAppRoute route={routes.pcai} />,
      },
      {
        path: routes.pcbe.path,
        element: <DSCCMicroAppRoute route={routes.pcbe} />,
      },
      shellEnableSearch1 && {
        path: routes.search.path,
        element: <DSCCMicroAppRoute route={routes.search} />,
      },
      {
        path: routes.secrets.path,
        element: <DSCCMicroAppRoute route={routes.secrets} />,
      },
      {
        path: routes.setup.path,
        element: <DSCCMicroAppRoute route={routes.setup} />,
      },
      shellEnableShapeshifter1 && {
        path: routes.shapeshifter.path,
        element: <DSCCMicroAppRoute route={routes.shapeshifter} />,
      },
      (shellEnableSFM1 || subscriptions["FABMGMT"]) && {
        path: routes.sfm.path,
        element: <DSCCMicroAppRoute route={routes.sfm} />,
      },
      {
        path: routes.tasks.path,
        element: <DSCCMicroAppRoute route={routes.tasks} />,
      },
      {
        path: routes.vcf.path,
        element: (
          <ShellRoute Header={DSCCHeader} title={routes.vcf.title}>
            <VCF />
          </ShellRoute>
        ),
      },
      dsccWebhooksService && {
        path: routes.webhooks.path,
        element: <DSCCMicroAppRoute route={routes.webhooks} />,
      },
      (shellEnableZerto1 || subscriptions["DISASTER_RECOVERY"]) && {
        path: routes.zerto.path,
        element: <DSCCMicroAppRoute route={routes.zerto} />,
      },
    ].filter(Boolean)
  )
}

export function AuthenticatedROPRoutes() {
  const { "shell-enable-announcements-1": shellEnableAnnouncements1 } =
    useFlags()
  return useRoutes(
    [
      {
        path: routes.launchpad.path,
        element: (
          <ShellRoute Header={ROPHeader}>
            <Launchpad />
          </ShellRoute>
        ),
      },
      {
        path: "*",
        element: (
          <ShellRoute Header={ROPHeader}>
            <NotFound />
          </ShellRoute>
        ),
      },
      {
        path: routes["allow-deny-manager"].path,
        element: <ROPMicroAppRoute route={routes["allow-deny-manager"]} />,
      },
      {
        path: routes.atlasRop.path,
        element: <ROPMicroAppRoute route={routes.atlasRop} />,
      },
      shellEnableAnnouncements1 && {
        path: routes.announcements.path,
        element: <ROPMicroAppRoute route={routes.announcements} />,
      },
      {
        path: routes.audit.path,
        element: <ROPMicroAppRoute route={routes.audit} />,
      },
      {
        path: routes["cluster-status"].path,
        element: <ROPMicroAppRoute route={routes["cluster-status"]} />,
      },
    ].filter(Boolean)
  )
}
