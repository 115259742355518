import { BUSINESS_UNIT } from "./constants"
import { buildTitle } from "../utils/title"
import { getRouteName } from "../utils/location"

export const staticValuesPlugin = () => {
  const name = "static-values-plugin"
  const type = "enrichment"

  function setup() {}

  function execute(event) {
    if (event.event_properties) {
      event.event_properties.businessUnit = BUSINESS_UNIT
    }
    return event
  }

  return {
    name,
    type,
    setup,
    execute,
  }
}

export const locationPlugin = () => {
  const name = "location-plugin"
  const type = "enrichment"

  function setup() {}

  function execute(event) {
    if (event.event_properties) {
      const data = new URL(event.event_properties.url ?? window.location.href)
      event.event_properties.location = {
        hash: data.hash,
        hostname: data.hostname,
        href: data.href,
        origin: data.origin,
        pathname: data.pathname,
        search: data.search,
      }
      event.event_properties.title = buildTitle(data.pathname)
      event.event_properties.service = getRouteName(data.pathname)
    }
    return event
  }

  return {
    name,
    type,
    setup,
    execute,
  }
}
