import { Text } from "grommet"
import { useTranslation } from "react-i18next"
import { useTiles } from "../../hooks"
import { launchpadItems } from "./launchpad-data"
import { LaunchpadGrid } from "./launchpad-grid"
import { LaunchpadLayout } from "./launchpad-layout"
import { getTestIdAttribute, launchpad } from "../../utils/test-ids"

function LoadingGrid() {
  const { t } = useTranslation()

  return (
    <Text size="small" margin="auto" {...getTestIdAttribute(launchpad.loading)}>
      {t("loading")}
    </Text>
  )
}

export function Launchpad() {
  const { loading, tiles } = useTiles(launchpadItems)

  return (
    <LaunchpadLayout>
      {loading ? (
        <LoadingGrid />
      ) : (
        <LaunchpadGrid dataTestId={launchpad.grid} tiles={tiles} />
      )}
    </LaunchpadLayout>
  )
}
