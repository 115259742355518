import { flagManager } from "./flag-manager"
import i18n from "./i18n-config/i18n"
import AuthService from "./oauth/auth-service"
import rtmClient from "./rtm-client"
import { FLAG_RTM } from "./utils/constants"
import { env } from "./utils/env"

export function createShellAPI() {
  const isRTMEnabled = flagManager.getFlag(FLAG_RTM, false)

  return {
    /**
     * get cluster-specific base URLs
     * @returns {{api_url: string, console_url: string}}
     */
    getBaseURLs() {
      return {
        api_url: env.REACT_APP_API_URL,
        console_url: env.REACT_APP_CONSOLE_URL,
      }
    },

    /**
     * get the value of a LaunchDarkly flag
     * @param {string} key
     * @param {boolean} defaultValue
     * @returns {boolean}
     */
    getFlag(key, defaultValue) {
      return flagManager.getFlag(key, defaultValue)
    },

    /**
     * get the selected language
     * @returns {string}
     */
    getLanguage() {
      return i18n.language
    },

    /**
     * get the Launchdarkly config
     * @returns {{clientSideID: string, ldClient: Object, options: Object, user: {key: string, email: string, custom: {applicationCustomerID: string}}}}
     */
    getLDConfig() {
      return flagManager.getLDConfig()
    },

    /**
     * get the selected language
     * @deprecated in favor of getLanguage()
     * @returns {Promise<string>}
     */
    getLocale() {
      return new Promise((resolve) => {
        resolve(i18n.language)
      })
    },

    /**
     * get the auth token
     * @async
     * @returns {Promise<string>}
     */
    async getToken() {
      const { access_token } = await AuthService.getUser()
      return access_token
    },

    /**
     * get the user information
     * @async
     * @returns {Promise<{email: string; givenName: string; lastName: string}>}
     */
    async getUser() {
      const { profile } = await AuthService.getUser()
      return {
        email: profile.email,
        givenName: profile.given_name,
        lastName: profile.family_name,
      }
    },

    rtm: {
      /**
       * get current RTM connection status
       * @returns {boolean}
       */
      isConnected() {
        return isRTMEnabled && rtmClient.isConnected()
      },

      /**
       * listen to RTM connection change events
       * @param {any} callback
       * @returns {void}
       */
      addConnectionChangeListener(callback) {
        if (!isRTMEnabled) return

        rtmClient.addConnectionChangeListener(callback)
      },

      /**
       * remove RTM connection change listener
       * @param {any} callback
       * @returns {boolean}
       */
      removeConnectionChangeListener(callback) {
        return (
          isRTMEnabled && rtmClient.removeConnectionChangeListener(callback)
        )
      },

      /**
       * @callback callback
       * @param {{resourceUri: string, resource?: Object}} eventDetails
       * @returns {void}
       *
       * subscribe to RTM events
       * @param {string[]} resourceUris
       * @param {callback} callback
       * @returns {void}
       */
      subscribe(resourceUris, callback) {
        if (!isRTMEnabled) return

        rtmClient.subscribe(resourceUris, callback)
      },

      /**
       * unsubscribe from RTM events
       * @param {string[]} resourceUris
       * @param {callback} callback
       * @returns {void}
       */
      unsubscribe(resourceUris, callback) {
        if (!isRTMEnabled) return

        rtmClient.unsubscribe(resourceUris, callback)
      },
    },
  }
}
