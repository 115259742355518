import { Outlet } from "react-router-dom"
import { DSCCHeader, MicroApp } from "../components"
import { ShellRoute } from "./shell-route"
import { routes } from "../shell-routes"

export function Dom() {
  return (
    <ShellRoute Header={DSCCHeader} title={routes["dom-fleet"].title}>
      <Outlet />
    </ShellRoute>
  )
}
Dom.ApolloObject = () => <MicroApp route={routes["dom-apollo-object"]} />
Dom.Arcus = () => <MicroApp route={routes["dom-arcus"]} />
Dom.DataAccess = () => <MicroApp route={routes["dom-data-access"]} />
Dom.EdgeStack = () => <MicroApp route={routes["dom-edgestack"]} />
Dom.File = () => <MicroApp route={routes["dom-file"]} />
Dom.Fleet = () => <MicroApp route={routes["dom-fleet"]} />
Dom.Homefleet = () => <MicroApp route={routes["dom-homefleet"]} />
Dom.Nimble = () => <MicroApp route={routes["dom-nimble"]} />
Dom.ObjectStorage = () => <MicroApp route={routes["dom-objectStorage"]} />
Dom.Primera = () => <MicroApp route={routes["dom-primera"]} />
Dom.SDS = () => <MicroApp route={routes["dom-sds"]} />
