import { Box, Button, Card, Text } from "grommet"
import { ShareRounded } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getTestIdAttribute, miniLaunchpad } from "../utils/test-ids"

export function MiniLaunchPadFeatureItem({ setOpen, content }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card
      elevation="small"
      round="4px"
      width={{ min: "240px" }}
      height={{ min: "40px" }}
      {...getTestIdAttribute(miniLaunchpad.item)}
    >
      <Button
        plain={true}
        href={content.destinationPath}
        target="_self"
        hoverIndicator={"#F4F6F9"}
        fill="vertical"
        rel="noopener noreferrer"
        onClick={(event) => {
          event.preventDefault()
          navigate(content.destinationPath, {
            state: { title: content.title },
          })

          setOpen(false)
        }}
      >
        <Box
          align="center"
          direction="row"
          fill="vertical"
          pad="4px"
          gap="small"
        >
          <Box align="center" background={"#E2E6EA"} pad="8px" round="4px">
            {content.icon("16px")}
          </Box>
          <Text
            color="#444"
            size="16px"
            {...getTestIdAttribute(content.dataTestIds.title)}
          >
            {t(content.title)}
          </Text>
        </Box>
      </Button>
    </Card>
  )
}

export function MiniLaunchPadServiceItem({ setOpen, content }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card
      elevation="small"
      round="4px"
      width={{ min: "240px" }}
      height={{ min: "40px" }}
      border={{
        side: "left",
        color: content.color,
        size: "8px",
      }}
      {...getTestIdAttribute(miniLaunchpad.item)}
    >
      <Button
        plain={true}
        href={content.destinationPath}
        hoverIndicator={"#F4F6F9"}
        target={content.type === "external" ? "_blank" : "_self"}
        fill="vertical"
        rel="noopener noreferrer"
        onClick={(event) => {
          if (content.type !== "external") {
            event.preventDefault()
            navigate(content.destinationPath, {
              state: { title: content.title },
            })
          }
          setOpen(false)
        }}
      >
        <Box
          align="center"
          direction="row"
          fill="vertical"
          pad="small"
          gap="6px"
        >
          <Text
            color="#444"
            size="16px"
            {...getTestIdAttribute(content.dataTestIds.title)}
          >
            {t(content.title)}
          </Text>
          {content.type === "external" && <ShareRounded size="16px" />}
        </Box>
      </Button>
    </Card>
  )
}
