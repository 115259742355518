import { Flash, FlashItem } from "@storage/react-flash"
import { Box } from "grommet"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavigationEventType } from "../events"
import { env } from "../utils/env"
import { getTestIdAttribute, launchpad } from "../utils/test-ids"

export default function FlashMessage() {
  const [deniedPath, setDeniedPath] = useState(null)
  const [showFlash, setShowFlash] = useState(false)
  const { t } = useTranslation()

  const handleDeniedPathEvent = (e) => {
    setDeniedPath(env.REACT_APP_CONSOLE_URL + e.detail.deniedPath)
    setShowFlash(true)
  }

  useEffect(() => {
    window.addEventListener(NavigationEventType.DENIED, handleDeniedPathEvent)
    return () => {
      window.removeEventListener(
        NavigationEventType.DENIED,
        handleDeniedPathEvent
      )
    }
  }, [])

  return (
    <Box className="absolute left-0 mt-36 w-full">
      <Flash
        severity="warning"
        open={showFlash}
        onOpenChange={setShowFlash}
        duration={5000}
        {...getTestIdAttribute(launchpad.flash)}
      >
        <FlashItem variant="title">{t("flashWarningTitle")}</FlashItem>
        <FlashItem variant="message">{deniedPath}</FlashItem>
      </Flash>
    </Box>
  )
}
