import { WebStorageStateStore } from "oidc-client"
import { AUTHORITY, CLIENT_ID } from "./constants"

const url = `${window.location.protocol}//${window.location.host}`

const oidcConfig = {
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  redirect_uri: `${url}/auth`,
  response_type: "code",
  post_logout_redirect_uri: url,
  scope: "openid profile email",
  silent_redirect_uri: `${url}/auth`,
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 300,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  metadataSeed: {
    // ping deviates from the standard (ping_end_session_endpoint)
    end_session_endpoint: `${AUTHORITY}/idp/startSLO.ping`,
  },
}

export default oidcConfig
