import { lazy, Suspense, useCallback, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { TOKEN_REFRESH_EVENT_TYPE } from "../events"
import authService from "../oauth/auth-service"
import { env } from "../utils/env"

const SearchPlugin = lazy(() => import("./global-search-plugin"))

export default function GlobalSearch({ service }) {
  const [token, setToken] = useState()

  const getToken = useCallback(async (signal) => {
    const { access_token } = await authService.getUser()
    if (!signal?.aborted) {
      setToken(access_token)
    }
  }, [])

  useEffect(() => {
    const ac = new AbortController()
    const listener = () => {
      getToken(ac.signal)
    }
    listener()
    window.addEventListener(TOKEN_REFRESH_EVENT_TYPE, listener)
    return () => {
      ac.abort()
      window.removeEventListener(TOKEN_REFRESH_EVENT_TYPE, listener)
    }
  }, [getToken])

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        {token && (
          <SearchPlugin
            authToken={token}
            baseUrl={env.REACT_APP_API_URL}
            initialService=""
          />
        )}
      </Suspense>
    </ErrorBoundary>
  )
}
