// mock route in place of ccsURL/logout
export function CCSLogout() {
  // CCS logout will redirect to idp for federated logout

  return (
    <div>
      <p>Successfully logged out.</p>
    </div>
  )
}
