import { useFlags } from "launchdarkly-react-client-sdk"
import { lazy, Suspense, useCallback, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { TOKEN_REFRESH_EVENT_TYPE } from "../events"
import authService from "../oauth/auth-service"
import { env } from "../utils/env"

const TaskBannerPlugin = lazy(() => import("./task-banner-plugin"))

export default function TaskBanner() {
  const { "shell-enable-rtm-1": shellEnableRTM1 } = useFlags()
  const [token, setToken] = useState()

  const getToken = useCallback(async (signal) => {
    const { access_token } = await authService.getUser()
    if (!signal?.aborted) {
      setToken(access_token)
    }
  }, [])

  useEffect(() => {
    const ac = new AbortController()
    getToken(ac.signal)
    window.addEventListener(TOKEN_REFRESH_EVENT_TYPE, () => {
      getToken(ac.signal)
    })
    return () => {
      ac.abort()
      window.removeEventListener(TOKEN_REFRESH_EVENT_TYPE, () => {
        getToken(ac.signal)
      })
    }
  }, [getToken])

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        {token && (
          <TaskBannerPlugin
            authToken={token}
            baseUrl={env.REACT_APP_API_URL}
            rtm={shellEnableRTM1}
          />
        )}
      </Suspense>
    </ErrorBoundary>
  )
}
