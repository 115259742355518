import { Layer, Box, Button, Heading, Text } from "grommet"
import { FormClose } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { getTestIdAttribute, additionalServices } from "../utils/test-ids"

const ExternalLinkConfirmation = ({ setOpen, ...rest }) => {
  const { content } = rest
  const { t } = useTranslation()
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Layer
      position="center"
      onClickOutside={setOpen}
      onEsc={onClose}
      {...getTestIdAttribute(additionalServices.modal)}
    >
      <Box pad="medium" gap="medium">
        <Box direction="row" align="center" justify="between">
          <Heading color="#444" level={2} margin="none" weight={600}>
            {t("modalTitle")}
          </Heading>
          <Button
            icon={<FormClose size="24px" />}
            onClick={onClose}
            pad="4px"
            {...getTestIdAttribute(`${additionalServices.modal}-x-button`)}
          />
        </Box>
        <Text color="#444" size="16px">
          {t("modalBody")}
        </Text>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "8px" }}
        >
          <Button
            label={
              <Box pad={{ vertical: "1px" }}>
                <Text color="#444" size="19px" weight="bold">
                  {t("modalStayHere")}
                </Text>
              </Box>
            }
            onClick={onClose}
            secondary
            type="button"
            {...getTestIdAttribute(`${additionalServices.modal}-close-button`)}
          />

          <Button
            label={
              <Box pad={{ vertical: "1px" }}>
                <Text size="19px">{t("modalAtlasActionButtonLabel")}</Text>
              </Box>
            }
            href={content.learnMoreLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
            primary
            type="button"
            {...getTestIdAttribute(`${additionalServices.modal}-external-link`)}
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default ExternalLinkConfirmation
