import { Box, Button } from "grommet"
import { Article } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { getTestIdAttribute, header } from "../utils/test-ids"

export default function ArticlesButton() {
  const { t } = useTranslation()

  return (
    <Button
      a11yTitle={t("tipArticles")}
      icon={<Article size="large" />}
      onClick={(e) => {
        e.preventDefault()
        dispatchEvent(new Event("contextHelp"))
      }}
      tip={{
        content: (
          <Box {...getTestIdAttribute(header.articles.tip)}>
            {t("tipArticles")}
          </Box>
        ),
      }}
      {...getTestIdAttribute(header.articles.icon)}
    />
  )
}
