import { Identify, identify } from "@amplitude/analytics-browser"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { isInternal } from "../analytics"
import authService from "./auth-service"
import { CID_KEY, REDIRECT_URI_KEY } from "./constants"

export function LoginRedirect({ children }) {
  const client = useLDClient()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // ensure user is logged in
  useEffect(() => {
    async function loadUser() {
      const user = await authService.getUser()

      if (user) {
        setIsAuthenticated(true)
      } else {
        // store destination url so it can be loaded after authentication
        window.sessionStorage.setItem(
          REDIRECT_URI_KEY,
          window.location.href.replace(window.location.origin, "")
        )

        // if we have cid in localStorage, login with that value
        // e.g. when opening a new tab from an existing session
        const cidFromStorage = localStorage.getItem(CID_KEY)
        if (cidFromStorage) {
          return authService.login({ cid: cidFromStorage })
        }

        navigate("/login", { replace: true })
      }
    }

    // only run if user not already authenticated
    if (!isAuthenticated) {
      loadUser()
    }
  }, [isAuthenticated, navigate])

  // update LD client with authenticated user to fetch user-targetted flags
  useEffect(() => {
    async function ldIdentify() {
      const user = await authService.getUser()
      const appCustID = authService.getUserCtx(user.access_token)
      const ldUser = {
        key: appCustID,
        email: user.profile.email,
        custom: { applicationCustomerId: appCustID },
      }
      // log on rejection, will fail if client was unable to initialize
      client.identify(ldUser).catch((error) => console.error(error))
    }

    // only run once LD client is initialized and user is authenticated
    if (client && isAuthenticated) {
      ldIdentify()
    }
  }, [client, isAuthenticated])

  // set amplitude user properties post-login
  useEffect(() => {
    async function ampIdentify() {
      const user = await authService.getUser()
      const event = new Identify()
      event.setOnce("isInternal", isInternal(user?.profile?.email))
      identify(event)
    }

    if (isAuthenticated) {
      ampIdentify()
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return children ? children : <Outlet />
}
