import { useQuery } from "@tanstack/react-query"
import { api } from "../api"
import { isDSCC } from "../utils/env"

/**
 * Fetch the user's allowed subscriptions.
 */
export default function useSubscriptions() {
  const subscriptionsQuery = useQuery({
    queryKey: ["subscriptions"],
    queryFn: api.getSubscriptions,
    enabled: isDSCC(),
  })

  const subscriptions = {}

  for (const sub of subscriptionsQuery.data?.items ?? []) {
    subscriptions[sub.productCategory] = sub.allowed
  }

  return {
    loading: subscriptionsQuery.isLoading,
    subscriptions,
  }
}
