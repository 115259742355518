import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Grommet } from "grommet"
import { hpe } from "grommet-theme-hpe"
import AppRouter from "./app-router"
import { StorageEventHandler } from "./oauth"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cache for 10 mins to prevent fetching too often
      staleTime: 10 * 60 * 1000,
      // disable retries in Cypress environment to enable failure testing
      retry: window.Cypress ? false : 3,
    },
  },
})

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Grommet full theme={hpe}>
        <StorageEventHandler />
        <AppRouter />
      </Grommet>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
