import { Events } from "./constants"
import { NavigationEventType } from "../events"

function onUrlChange(callback) {
  let lastUrl
  return (e) => {
    const url = e.detail.href
    if (url !== lastUrl) {
      callback(Events.SCREEN_VIEWED, { url })
      lastUrl = url
    }
  }
}

/**
 * Track URL changes.
 */
export class UrlChange {
  #isRunning = false
  #listener

  constructor(callback) {
    this.#listener = onUrlChange(callback)
  }

  start() {
    if (this.#isRunning) {
      return
    }

    window.addEventListener(NavigationEventType.SHELL, this.#listener)
    this.#isRunning = true
  }

  stop() {
    window.removeEventListener(NavigationEventType.SHELL, this.#listener)
    this.#isRunning = false
  }
}

/**
 * Fire a callback when navigating away from the page.
 */
export class PageHide {
  #listener

  constructor(callback) {
    this.#listener = callback
  }

  start() {
    window.addEventListener("pagehide", this.#listener)
  }

  stop() {
    window.removeEventListener("pagehide", this.#listener)
  }
}
