import { Box, Button, Card, CardBody, Text } from "grommet"
import { ShareRounded } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getTestIdAttribute } from "../../utils/test-ids"

export function FeatureTile({ content }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card
      elevation="small"
      width={{ max: "288px" }}
      height={{ min: "230px" }}
      {...getTestIdAttribute(content.dataTestIds.container)}
    >
      <CardBody align="start" justify="between" gap="small" pad="20px">
        <Box gap="small">
          {content.icon()}
          <Text
            color="#444"
            size="24px"
            weight={500}
            {...getTestIdAttribute(content.dataTestIds.title)}
          >
            {t(content.title)}
          </Text>
          <Text
            size="small"
            {...getTestIdAttribute(content.dataTestIds.description)}
          >
            {t(content.description)}
          </Text>
        </Box>
        <Button
          href={content.destinationPath}
          target="_self"
          rel="noopener noreferrer"
          label={
            <Box
              direction="row"
              gap="xsmall"
              align="center"
              pad={{ vertical: "1px" }}
            >
              <Text color="#444" size="19px" weight="bold">
                {t(content.tileButtonLabel)}
              </Text>
            </Box>
          }
          onClick={(event) => {
            event.preventDefault()
            navigate(content.destinationPath, {
              state: { title: content.title },
            })
          }}
          secondary
          type="button"
          {...getTestIdAttribute(content.dataTestIds.button)}
        />
      </CardBody>
    </Card>
  )
}

export function ServiceTile({ content }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isExternal = content.type === "external"

  return (
    <Card
      elevation="small"
      width={{ max: "288px" }}
      height={{ min: "250px" }}
      border={{
        side: "top",
        color: content.color,
        size: "12px",
      }}
      {...getTestIdAttribute(content.dataTestIds.container)}
    >
      <CardBody align="start" justify="between" gap="small" pad="20px">
        <Box gap="small">
          <Text
            color="#444"
            size="24px"
            weight={500}
            {...getTestIdAttribute(content.dataTestIds.title)}
          >
            {t(content.title)}
          </Text>
          <Text
            size="small"
            {...getTestIdAttribute(content.dataTestIds.description)}
          >
            {t(content.description)}
          </Text>
        </Box>
        <Button
          href={content.destinationPath}
          target={isExternal ? "_blank" : "_self"}
          rel="noopener noreferrer"
          label={
            <Box
              direction="row"
              gap="xsmall"
              align="center"
              pad={{ vertical: "1px" }}
            >
              <Text color="#444" size="19px" weight="bold">
                {t(content.tileButtonLabel)}
              </Text>
              {isExternal && <ShareRounded color="#444" />}
            </Box>
          }
          onClick={(event) => {
            if (content.type !== "external") {
              event.preventDefault()
              navigate(content.destinationPath, {
                state: { title: content.title },
              })
            }
          }}
          secondary
          type="button"
          {...getTestIdAttribute(content.dataTestIds.button)}
        />
      </CardBody>
    </Card>
  )
}
