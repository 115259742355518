import { useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { env, isONPREM } from "../utils/env"

const ONPREM_REDIRECT_URI_KEY = "dscc-onprem-redirect-uri"
const ONPREM_API_CERT_KEY = "dscc-onprem-cert"

export function OnPremCert({ children }) {
  const navigate = useNavigate()

  useLayoutEffect(() => {
    async function redirectForCert() {
      // check session storage for cert acceptance identifier
      const certAccepted = !!sessionStorage.getItem(ONPREM_API_CERT_KEY)

      if (!certAccepted) {
        // set cert acceptance identifier to avoid endless loop
        sessionStorage.setItem(ONPREM_API_CERT_KEY, JSON.stringify(true))

        // handle deep links
        const destination = window.location.href.replace(
          window.location.origin,
          ""
        )

        // no need to redirect to root
        if (destination !== "/") {
          sessionStorage.setItem(ONPREM_REDIRECT_URI_KEY, destination)
        }

        window.location.replace(
          `${env.REACT_APP_API_URL}/data-services/v1alpha1/onprem-cert`
        )
      }

      // cert already accepted
      const redirectTo = sessionStorage.getItem(ONPREM_REDIRECT_URI_KEY)
      if (redirectTo) {
        // send user to their original destination
        sessionStorage.removeItem(ONPREM_REDIRECT_URI_KEY)
        return navigate(redirectTo, { replace: true })
      }
    }

    // only required for onprem self-signed certificates
    if (isONPREM() && process.env.NODE_ENV !== "development") {
      redirectForCert()
    }
  }, [navigate])

  return children
}
