import { useQuery } from "@tanstack/react-query"
import { api } from "../api"

/**
 * Fetch the user's authz permissions.
 */
export default function useAuthZ() {
  const result = useQuery({
    queryKey: ["access-controls"],
    queryFn: api.getAccessControls,
  })

  const permissions = result.data?.items ?? []

  return {
    loading: result.isLoading,
    permissions,
  }
}
