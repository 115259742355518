import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import authService from "./auth-service"
import { REDIRECT_URI_KEY } from "./constants"

export function AuthCallback() {
  const navigate = useNavigate()

  useEffect(() => {
    authService.signinRedirectCallback().then(() => {
      const redirectTo = window.sessionStorage.getItem(REDIRECT_URI_KEY) || "/"
      window.sessionStorage.removeItem(REDIRECT_URI_KEY)

      navigate(redirectTo, { replace: true })
    })
  }, [navigate])

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  )
}
