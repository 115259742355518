import { Box, Header, Text } from "grommet"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import BrandButton from "./brand-button"
import MiniLaunchPad from "./mini-launchpad"
import { getTestIdAttribute, header } from "../utils/test-ids"

const Pipe = () => (
  <Text
    color="text-weak"
    margin={{ horizontal: "8px" }}
    style={{ userSelect: "none" }}
  >
    |
  </Text>
)

export default function ShellHeader({
  brandTitle,
  children,
  dataTestId = header.container,
  serviceTitle,
}) {
  const { t } = useTranslation()

  return (
    <Header
      border={{ side: "bottom", color: "border", size: "1px" }}
      color="background"
      height="72px"
      pad={{ horizontal: "medium" }}
      {...getTestIdAttribute(dataTestId)}
    >
      <Box align="center" direction="row">
        <BrandButton>{brandTitle}</BrandButton>
        {serviceTitle && (
          <>
            <Pipe />
            <ErrorBoundary
              fallback={<Text color="text-strong">{t(serviceTitle)}</Text>}
            >
              <MiniLaunchPad serviceTitle={serviceTitle} />
            </ErrorBoundary>
          </>
        )}
      </Box>
      <Box align="center" direction="row" gap="4px">
        {children}
      </Box>
    </Header>
  )
}
