import { useEffect } from "react"
import authService from "./auth-service"
import { CID_KEY } from "./constants"

export function StorageEventHandler() {
  useEffect(() => {
    const listener = async (e) => {
      // ignore storage events for other keys
      if (e.key !== CID_KEY) {
        return
      }

      if (e.newValue == null) {
        // key was removed by another tab logging out, log out this tab
        return authService.logoutLocal()
      }

      const existingID = await authService.getApplicationCustomerID()
      if (e.newValue === existingID) {
        // same cid as we currently have, do nothing
        return
      }

      // new cid, get new tokens
      return authService.login({ cid: e.newValue })
    }

    window.addEventListener("storage", listener)

    return () => window.removeEventListener("storage", listener)
  }, [])

  return null
}
