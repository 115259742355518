import { matchRoutes } from "react-router-dom"
import { helpData } from "./help-data"
import { getRoute, getRouteName } from "../utils/location"
import { env } from "../utils/env"

const { publications, topics } = helpData

const helpPaths = Object.keys(topics).map((path) => ({ path }))

/**
 * Fetches the external publication URL for a service
 * (Green banner on the help-widget)
 * @param {string} href
 * @returns {string}
 */
export function getPublication(href) {
  let pathname
  try {
    pathname = new URL(href).pathname
  } catch (error) {
    return
  }
  return publications[getRouteName(pathname)]
}

/**
 * Fetches the topic-id for an input URL
 * @param {string} href
 * @returns {string}
 */
export function getTopic(href) {
  let url
  try {
    url = new URL(href)
  } catch (error) {
    return topics.default
  }
  const route = getRoute(url.pathname)
  const matched = matchRoutes(helpPaths, url.pathname)

  if (!matched) return topics[route.navPath] || topics.default

  if (!url.search) return topics[matched[0].route.path]

  const matchingPaths = Object.keys(topics).filter((matchingPath) =>
    // concatenating "?" to the path to avoid adding unintended entries and
    // add just the paths that start with `matched[0].route.path` and have a query-param
    matchingPath.startsWith(matched[0].route.path + "?")
  )

  return (
    topics[matchParams(url, matchingPaths)] || topics[matched[0].route.path]
  )
}

/**
 * Matches a URL (with query-parameters) against an array of URLs (with query-parameters),
 * returns the URL with maximum matching query-parameter
 * @param {URL} currentUrl
 * @param {string[]} urlArray
 * @returns {string}
 */
export function matchParams(currentUrl, urlArray) {
  const targetParams = currentUrl.searchParams
  let maxMatches = 0
  let matchingURL = urlArray[0]

  for (const url of urlArray) {
    let urlParams
    try {
      urlParams = new URL(url.toUpperCase(), env.REACT_APP_CONSOLE_URL)
        .searchParams
    } catch (error) {
      urlParams = new URL(env.REACT_APP_CONSOLE_URL).searchParams
    }

    const isExactMatch =
      targetParams.toString().toUpperCase() === urlParams.toString()

    if (isExactMatch) return url

    const numMatchingParams = [...targetParams.entries()].filter(
      ([key, value]) => urlParams.get(key.toUpperCase()) === value.toUpperCase()
    ).length

    if (numMatchingParams > maxMatches) {
      maxMatches = numMatchingParams
      matchingURL = url
    }
  }

  if (maxMatches === 0) return currentUrl.pathname

  return matchingURL
}
