import { DSCCHeader, MicroApp, PageLayout, ROPHeader } from "../components"

export function ShellRoute({ children, Header, title }) {
  return (
    <PageLayout header={<Header serviceTitle={title} />}>{children}</PageLayout>
  )
}

export function DSCCMicroAppRoute({ route }) {
  return (
    <ShellRoute Header={DSCCHeader} title={route.title}>
      <MicroApp route={route} />
    </ShellRoute>
  )
}

export function ROPMicroAppRoute({ route }) {
  return (
    <ShellRoute Header={ROPHeader} title={route.title}>
      <MicroApp route={route} />
    </ShellRoute>
  )
}
