import { getTestIdAttribute, miniLaunchpad } from "../utils/test-ids"
import { Box, Text, Grid } from "grommet"
import { useTranslation } from "react-i18next"
import {
  MiniLaunchPadFeatureItem,
  MiniLaunchPadServiceItem,
} from "./mini-launchpad-item"
import { Products, TileType } from "../utils/constants"
import { env } from "../utils/env"

function HorizontalPipe() {
  return (
    <Box border={{ side: "top", color: "#EEEEEE" }} fill="horizontal"></Box>
  )
}

export default function MiniLaunchPadContent({ contents, setOpen }) {
  const { t } = useTranslation()

  const columns = { count: contents.length > 8 ? 3 : 2, size: "auto" }

  const features = contents.filter((c) => c.tileType === TileType.FEATURE)
  const services = contents.filter((c) => c.tileType === TileType.SERVICE)

  const featureGrid = features.length > 0 && (
    <Grid
      columns={columns}
      gap="small"
      {...getTestIdAttribute(miniLaunchpad.grid)}
    >
      {features.map((feature) => (
        <MiniLaunchPadFeatureItem
          content={feature}
          key={feature.key}
          setOpen={setOpen}
        />
      ))}
    </Grid>
  )
  const serviceGrid = services.length > 0 && (
    <Grid
      columns={columns}
      gap="small"
      {...getTestIdAttribute(miniLaunchpad.grid)}
    >
      {services.map((service) => (
        <MiniLaunchPadServiceItem
          content={service}
          key={service.key}
          setOpen={setOpen}
        />
      ))}
    </Grid>
  )

  return (
    <Box gap="medium">
      <Text weight={500} color="text-strong">
        {t(Products[env.REACT_APP_PRODUCT])}
      </Text>
      {serviceGrid}
      {features.length > 0 && services.length > 0 && <HorizontalPipe />}
      {featureGrid}
    </Box>
  )
}
