import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuthService from "../oauth/auth-service"
import { APP_INSTANCE_ID, CCS_LOGIN_URL, REDIRECT_URI_KEY } from "../oauth"
import { routes } from "../shell-routes"

const serviceDestinations = {
  BKUP: `${routes.atlas.navPath}/dashboard`,
  BS: routes.block.navPath,
  DOM: `${routes["dom-fleet"].navPath}/dashboard`,
  DR: routes.zerto.navPath,
  FS: routes["file-manager"].navPath,
  PCAI: routes.pcai.navPath,
  PCBE: routes.pcbe.navPath,
  SFM: `${routes.sfm.navPath}/dashboard`,
  VCF: routes.vcf.navPath,
}

export function Login() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    // Login Scenarios
    // +-------+-------+-----------------------------------+
    // |  cid  | user  |              action               |
    // +-------+-------+-----------------------------------+
    // | true  | true  | begin OAuth flow for new tokens   |
    // | true  | false | begin OAuth flow for new tokens   |
    // | false | true  | redirect home                     |
    // | false | false | redirect to CCS for initial login |
    // +-------+-------+-----------------------------------+
    async function login() {
      // GLP service offer deep link support
      // check for `service_offer` query param and if found translate to destination URL
      // store URL in session storage for redirection after successful login
      const slug = searchParams.get("service_offer")
      if (slug && serviceDestinations[slug]) {
        const destinationURL = serviceDestinations[slug]
        sessionStorage.setItem(REDIRECT_URI_KEY, destinationURL)
      }

      // we have cid search param, begin OAuth flow whether user already signed in or not
      // could be a new cid that requires new tokens even if user already signed in
      const cid = searchParams.get("cid")
      if (cid) {
        return AuthService.login({ cid })
      }

      // no cid param and user already logged in, redirect home
      const user = await AuthService.getUser()
      if (user) {
        return navigate("/", { replace: true })
      }

      // no cid and no user
      // redirect to CCS for customer login
      window.location.replace(`${CCS_LOGIN_URL}?app=${APP_INSTANCE_ID}`)
    }

    login()
  }, [navigate, searchParams])

  return null
}
