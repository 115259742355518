import deMessages from "../locales/de-DE"
import enMessages from "../locales/en-US"
import esMessages from "../locales/es-ES"
import frMessages from "../locales/fr-FR"
import itMessages from "../locales/it-IT"
import jaMessages from "../locales/ja-JP"
import koMessages from "../locales/ko-KR"
import ptMessages from "../locales/pt-BR"
import ruMessages from "../locales/ru-RU"
import zhMessages from "../locales/zh-CN"

const locales = {
  de: {
    translation: deMessages,
  },
  en: {
    translation: enMessages,
  },
  es: {
    translation: esMessages,
  },
  fr: {
    translation: frMessages,
  },
  it: {
    translation: itMessages,
  },
  ja: {
    translation: jaMessages,
  },
  ko: {
    translation: koMessages,
  },
  pt: {
    translation: ptMessages,
  },
  ru: {
    translation: ruMessages,
  },
  zh: {
    translation: zhMessages,
  },
}
export default locales
