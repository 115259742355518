import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import translations from "./translations"

export const I18N_STORAGE_KEY = "dscc-lang"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: I18N_STORAGE_KEY,
      caches: ["localStorage"],
    },
  })

export default i18n
