import { Box, Grid, ResponsiveContext } from "grommet"
import { useContext } from "react"
import { FeatureTile, ServiceTile } from "./tile"
import { TileType } from "../../utils/constants"
import { getTestIdAttribute } from "../../utils/test-ids"

function calculateGridColumns(screenSize, numTiles) {
  switch (true) {
    case screenSize === "xsmall" || numTiles === 1:
      return 1
    case screenSize === "small" || numTiles < 3:
      return 2
    case screenSize === "medium" || numTiles < 4:
      return 3
    default:
      return 4
  }
}

export function LaunchpadGrid({ background, tiles, dataTestId }) {
  const size = useContext(ResponsiveContext)

  const services = tiles.filter((c) => c.tileType === TileType.SERVICE)
  const features = tiles.filter((c) => c.tileType === TileType.FEATURE)

  const columns = {
    count: calculateGridColumns(
      size,
      Math.max(services.length, features.length)
    ),
    size: "auto",
  }

  const serviceGrid = services.length > 0 && (
    <Grid
      columns={columns}
      gap="small"
      {...getTestIdAttribute(`${dataTestId}-services`)}
    >
      {services.map((tile) => (
        <ServiceTile content={tile} key={tile.key} />
      ))}
    </Grid>
  )

  const featureGrid = features.length > 0 && (
    <Grid
      columns={columns}
      gap="small"
      {...getTestIdAttribute(`${dataTestId}-features`)}
    >
      {features.map((tile) => (
        <FeatureTile content={tile} key={tile.key} />
      ))}
    </Grid>
  )

  return (
    <Box
      background={background}
      gap="large"
      {...getTestIdAttribute(dataTestId)}
    >
      {serviceGrid}
      {featureGrid}
    </Box>
  )
}
