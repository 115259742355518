import { initialize } from "launchdarkly-js-client-sdk"
import { LDConfig } from "./utils/launchdarkly"

export class FlagManager {
  constructor() {
    this.client = null
  }

  getFlag = (key, defaultValue) => {
    return this.client?.variation(key, defaultValue) ?? defaultValue
  }

  getLDConfig = () => {
    return {
      clientSideID: LDConfig.clientSideID,
      ldClient: this.client,
      options: LDConfig.options,
      user: this.client?.getUser() ?? {},
    }
  }

  init = async (user = { anonymous: true }) => {
    this.client = initialize(LDConfig.clientSideID, user, LDConfig.options)

    await this.client.waitUntilReady()

    return this
  }
}

export const flagManager = new FlagManager()
