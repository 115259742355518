import { Box, Heading } from "grommet"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { Products } from "../../utils/constants"
import { env } from "../../utils/env"
import { getTestIdAttribute, launchpad } from "../../utils/test-ids"

export function LaunchpadLayout({ children }) {
  const { t } = useTranslation()

  return (
    <Box alignSelf="center" flex="grow" pad="large" width="1280px">
      <Box gap="small" align="start">
        <Heading
          color="text-strong"
          margin={{ bottom: "large", top: "0px" }}
          weight={500}
          {...getTestIdAttribute(launchpad.welcome)}
        >
          {t("launchpadWelcomeMessage", {
            p_name: t(Products[env.REACT_APP_PRODUCT]),
          })}
        </Heading>
        <ErrorBoundary fallback={<></>}>{children}</ErrorBoundary>
      </Box>
    </Box>
  )
}
